import React from "react";

// We use Route in order to define the different routes of our application
import { Route, Routes } from "react-router-dom";

// We import all the components we need in our app
import Start from "./routes/start";
import Register from "./routes/register";
import Home from "./routes/home";
import Privacy from "./routes/privacy";
import About from "./routes/about";
//import TestGeocode from "./routes/test-geocode";
//import TestAthlete from "./routes/test-athlete";
//import TestUserAgent from "./routes/test-user-agent";
import Footer from "./components/footer";

//import Test from "./routes/test";
/*
return (
	<div id="page-container">
		<div id="content-wrap">
		 <Routes>
			 <Route exact path="/" element={<Test />} />
		 </Routes>
	 </div>
	<Footer />
</div>
);
<Route exact path="/test-geocode" element={<TestGeocode />} />
<Route exact path="/test-user-agent" element={<TestUserAgent />} />
<Route path="/test-athlete" element={<TestAthlete />} />

*/

const App = () => {

  return (
        <div id="page-container">
          <div id="content-wrap">
            <Routes>
              <Route exact path="/" element={<Start />} />
              <Route exact path="/app" element={<Home />} />
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/privacy" element={<Privacy />} />
              <Route exact path="/about" element={<About />} />
            </Routes>
          </div>
        <Footer />
      </div>
 );

};

export default App;
