import { Link } from "react-router-dom";
import { Block, Row, Col } from 'jsxstyle'
import React, { useEffect, useState } from "react";
import logo from '../../public/mountain-road_512.png';

const tagLines = [
  'The sky is the limit',
  'It’s all about elevation',
  'The only way is up',
  'Elevate your cycling game',
  'Climb the leaderboards',
  'Climb to the top',
  'Climbing the world',
  'Level up!',
  'Reach the stars!',
  'Trophies for climbing!',
  'Life is not fair!'
]

//Outclimb your friends , Level up your cycling game!

const getTagLine = () => {
  return tagLines[Math.floor(Math.random() * tagLines.length)];
}

const Header = ({profile, logout, goHome}) => {

	const [tagLine, setTagline] = useState(tagLines[0]);

	useEffect(() => {
		setTagline(getTagLine())
	},[])

  return (
    <>
      {profile ? (
        <Block style={{ marginTop: "25px", marginBottom: "0px" }}>
	        <Row justify-content="center">
	          <Link to="/" onClick={() => goHome()} style={{ textDecoration: 'none', color: '#444444', fontWeight: 300, letterSpacing: '1px', lineHeight: '28px', fontSize: '30px'}}>
	            Heightmeters
	          </Link>
	        </Row>
            <Block style={{marginTop: "-30px"}}>
                <Row justify-content="end">
                	<Link style={{ textDecoration: 'none', color: '#444444', fontWeight: 200, lineHeight: '16px', fontSize: '14px' }} onClick={() => goHome()}>
                        <img src={profile} alt="Profile Image" style={{borderRadius: "50%", width: '30px', marginRight: "4px"}}/>
                	</Link>
                </Row>
                <Row justify-content="end" >
                  <Link style={{ textDecoration: 'none', color: '#444444', fontWeight: 200, lineHeight: '17px', fontSize: '15px', marginTop: "4px" }} onClick={() => logout()}>
                    Logout
                  </Link>
                </Row>
            </Block>
			<Row justify-content="center" style={{  marginBottom: '25px', marginTop: '-16px', fontWeight: 300, lineHeight: '20px', fontSize: '18px'}}>
				{tagLine}
			</Row>
        </Block>
      ) : (
        <Block style={{ marginTop: "25px", marginBottom: "0px" }}>
            <Row justify-content="center" style={{ marginBottom: "-2px", height: '70px'}}>
              <Link to="/" onClick={() => goHome()}>
                  <img src={logo} alt="HeightNeters Logo" style={{width: '70px'}}/>
              </Link>
            </Row>
          <Row justify-content="center">
            <Link to="/" onClick={() => goHome()} style={{ textDecoration: 'none', color: '#444444', fontWeight: 300, letterSpacing: '1px', lineHeight: '28px', fontSize: '30px'}}>
              Heightmeters
            </Link>
          </Row>
          <Row justify-content="center" style={{ margin: '10px', marginTop: '8px', fontWeight: 300, lineHeight: '20px', fontSize: '18px'}}>
            {tagLine}
          </Row>
        </Block>
      )}
    </>

  );
}

/*
<Row justify-content="center">
  <Link to="/" style={{ textDecoration: 'none', color: '#444444', fontWeight: 300, lineHeight: '30px', fontSize: '30px'}}>
    Heightmeters
  </Link>
</Row>
<Row justify-content="center" style={{ margin: '15px', marginTop: '10px', fontWeight: 300, lineHeight: '20px', fontSize: '20px'}}>
  {getTagLine()}
</Row>
{profile && (
  <Block style={{marginTop: "-30px"}}>
    <Row justify-content="end">
      <img src={profile} alt="Profile Image" style={{borderRadius: "50%", width: '30px', marginRight: "4px"}}/>
    </Row>
    <Row justify-content="end">
      <Link style={{ textDecoration: 'none', color: '#444444', fontWeight: 200, lineHeight: '16px', fontSize: '14px', }} onClick={() => logout()}>
        Logout
      </Link>
    </Row>
  </Block>
)}
</Block>
</>
*/

export default Header;
