import './style/index.css';
import React from "react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
//import { ReactDOM } from 'react-dom/client';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container);

//STRICT MODE

root.render(
  <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
  </React.StrictMode>
);

/*
ReactDOM.hydrateRoot(
  document,
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);
*


//Not so strict
/*
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
*/
