import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Block, Row, Col } from 'jsxstyle'
import LoadingSpinner from "./spinner";
import { stringify } from 'querystring'
import { timePeriods, getLocalTime } from '../util.js'

//showCircle
export default function LeaderBoard({
		circleInfo,
		changeAthlete,
		athleteId,
		circleIndex,
		updateCircleIndex,
		circle,
		getCircle,
		sorted,
		setSorted,
		period,
		updatePeriod
	}) {

  const [circles, setCircles] = useState([]);
  const [isLoadingCircle, setIsLoadingCircle] = useState(false);

	//const [gonnaShowCircle, setGonnaShowCircle] = useState(false);

  useEffect( () => {
    if (circleInfo) {
      setCircles(circleInfo.sort((a, b) =>  a.level - b.level))
      getCircle(circleIndex ? circleInfo[circleIndex].id : circleInfo[0].id, period, sorted)
    }
  }, [circleInfo])

  const changeCircle = async (event) => {
    const circleIndex = event.target.value
    const circleId = circleInfo[circleIndex].id
    setIsLoadingCircle(true)
    updateCircleIndex(circleIndex)
    await getCircle(circleId, period, sorted);
    setTimeout(async () => {
      setIsLoadingCircle(false)
    }, 250);
 };

	//Uppdatera endast achivements
	const changePeriod = async (period) => {
	 setIsLoadingCircle(true)
	 await updatePeriod(period)
	 setTimeout(async () => {
		 setIsLoadingCircle(false)
	 }, 250);
	}

	//Uppdatera endast achivements
	const changeSorted = async (sort) => {
	 setIsLoadingCircle(true)
	 setSorted(sort)
	 await getCircle(circle._id, period, sort);
	 setTimeout(async () => {
	   setIsLoadingCircle(false)
	 }, 350);
	};

const renderTimeBar = () => {
	return (
		<Block>
			<Row justify-content="center" style={{ fontSize: '16px'}}>
				{timePeriods.map((timePeriod, i) => (
	 			 	<div key={i}>
						{period === i ? (
							<Link style={{margin: "3px" }}>
	 					  	{timePeriod}
	 						</Link>
						) : (
							<Link onClick={() => changePeriod(i)} style={{ textDecoration: 'none', margin: "3px" }}>
								{timePeriod}
							</Link>
						)}
				 	</div>
		 		))}
			</Row>
		</Block>
	)
}

const renderCircleBar = () => {
    if (circle && circles.length > 0) {
      return (
        <Block>
          <Row vertical-align="text-bottom" justify-content="center">
			<Col style={{marginRight: "7px", fontSize: '15px'}}>
            	Select circle:
			</Col>
			<Col style={{fontSize: '20px'}}>
	            <select defaultValue={circleIndex} onChange={changeCircle}>
	              {circles.map((circ, i) =>
	                <option key={circ.id} value={i}>{circ.name}</option>
	              )}
	            </select>
			</Col>
	      </Row>
        </Block>
      )
    }
  }

/*
<Row justify-content="center" style={{fontSize: '14px', marginTop: '4px'}}>
	Local time: {getLocalTime(circle.timeZone.gmtOffset).toLocaleString()}
</Row>
*/

/* Circle Page
{gonnaShowCircle && (
	<Row justify-content="center" style={{ margin: '5px' }}>
		<Link onClick={() => showCircle()} style={{ textDecoration: 'none', fontSize: '16px' }}>
		 More on {circle.name}
	 </Link>
	</Row>
)}
*/

  const Record = (agg, i) => (
     <tr key={i}>
       <td style={{ paddingRight: 5, paddingBottom: 2 }}>{agg.position ? agg.position : i+1}</td>
       {agg.athleteId === athleteId ? (
         <td style={{ paddingRight: 5 }}><Link>{agg.name}</Link>&nbsp;&nbsp;</td>
       ) : (
         <td style={{ paddingRight: 5 }}><Link onClick={() => {changeAthlete(agg.athleteId, circle._id)}} style={{ textDecoration: 'none' }}>{agg.name}</Link>&nbsp;&nbsp;</td>
       )}
       <td style={{ paddingRight: 5 }}>{agg.meters === 0 ? 0 : Math.round(agg.meters).toLocaleString().replace(',', ' ')}</td>
       <td style={{ paddingRight: 5 }}>{agg.HOD === 0 ? 0 : agg.HOD}</td>
     </tr>
  );

  const getLink = (type) => {
    if (sorted === type) {
      return (
        <Link style={{fontSize: 16, fontWeight: 300}} onClick={() => changeSorted(type)}>{type}</Link>
      )
    } else {
      return (
        <Link style={{fontSize: 16, fontWeight: 300, textDecoration: 'none'}} onClick={() => changeSorted(type)}>{type}</Link>
      )
    }
  }

  function renderCircle() {
    if (circle && circle.achievements) {

      const body = circle.achievements.map((agg, i) => {
        return Record(agg, i)
      })

      return (
        <table className="table table-striped" style={{ textAlign: "left", lineHeight: '18px', fontSize: '16px', marginTop: '2px', marginBottom: 25 }}>
          <thead style={{ marginTop: 8 }}>
            <tr style={{ fontSize: "16px", fontWeight: 100 }}>
              <th style={{ paddingRight: 15, fontWeight: 300 }}>#</th>
              <th style={{ paddingRight: 5, fontWeight: 300 }}>Name</th>
              <th style={{ paddingRight: 25 }}>{getLink('Meters')}</th>
              <th style={{ paddingRight: 5 }}>{getLink('HOD')}</th>
            </tr>
          </thead>
          <tbody>
            {body}
          </tbody>
        </table>
      )

    }
  }

  return (
    <Block style={{ width: '100%'}} >
			{circleInfo && (
			  <>
    		      <Row justify-content="center" style={{  marginTop: '3px', marginBottom: '8px',}}>
    		        {renderCircleBar()}
    		      </Row>
				  {circle && (
				    <Row justify-content="center" style={{  marginTop: '12px', letterSpacing: '0.5px', marginBottom: '7px', lineHeight: '22px', fontSize: '20px'}}>
			          {circle.name}
			        </Row>
			       )}
			  </>
			)}
      <Row justify-content="center" style={{ fontSize: 14, margin: "10px", marginTop: '3px', marginBottom: '4px' }}>
        {renderTimeBar()}
      </Row>
      {isLoadingCircle ? (
        <Row justify-content="center" style={{ marginTop: '10px' , marginBottom: '15px'}}>
          <LoadingSpinner />
        </Row>
      ) : (
        <Row justify-content="center">
          {renderCircle()}
        </Row>
      )}
    </Block>
  );

}
