import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Block, Row, div, Col } from 'jsxstyle'
import Header from "../components/header";
import { Link } from "react-router-dom";
import strava from '../../public/btn_strava_connectwith_light.png';
import { oauth } from '../oauth'

const API_URL = process.env.REACT_APP_API_URL

export default function Start() {

  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('hm-user'))
    //console.log('Start - user', user)
    if (user) {
      if (user.created) {
        navigate("/app");
      } else {
        localStorage.removeItem('hm-user')
      }
    }
  }, [])

  const loginStrava = async () => {
    try {
      const res = await oauth({
        scope: 'read,activity:read'
      })
      const { code } = res
      const response = await fetch(API_URL + 'strava/auth/' + code);
      const user = await response.json()
      if (user.athlete) {
        localStorage.setItem('hm-user', JSON.stringify(user.athlete))
        navigate("/app");
      } else if (user.strava) {
        localStorage.setItem('hm-user', JSON.stringify(user.strava))
        navigate("/register");
      }
    } catch (err) {
     alert('There was an error ' + err.message)
     console.log('There was an error ' + err.message)
    }
  }

  return (
    <>
      <Header />
      <Row justify-content="center">
          <Block style={{ fontWeight: 300, lineHeight: '19px', fontSize: '18px', maxWidth: '500px'}}>
            <Block style={{ textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginTop: '3px', fontSize: '17px', lineHeight: '20px'}}>
              <div style={{ alignItems: 'center', marginBottom: '3px', fontSize: '28px', lineHeight: '28px', fontWeight: 400}}>
                  Climb with us and rule
              </div>
              <div style={{ alignItems: 'center', marginBottom: '14px', fontSize: '26px', lineHeight: '28px', fontWeight: 400}}>
                  your circles
              </div>

              <div style={{ marginBottom: '6px'}}>
                  Compete on elevation gain and <br/> HOD (Height Over Distance).
              </div>

              <div style={{ marginBottom: '6px'}}>
                  Win trophies every day, week, month and year. Trophies are awarded to all your circles.
              </div>

              <div style={{ marginBottom: '6px'}}>
                Circles are geographical enities ranging from your neighbourhood or home town (your home circle) to circle planet.
              </div>

              <div style={{ marginBottom: '6px'}}>
                  Compatible with Strava – your elevation data is fetched automatically.
              </div>

              <div style={{ marginBottom: '6px' }}>
                  Read more about Heightmeters <Link to="/about" style={{ textDecoration: 'none' }}>here.</Link>
              </div>

              <div style={{ marginTop: '0px'}}>
                  Use Strava to Login or Register at Heightmeters.com
              </div>

              <div justify-content="center" style={{ marginTop: '6px'}}>
                <Link onClick={() => loginStrava()}>
                  <img src={strava} alt="Strava Login" style={{ width: '193px' }}/>
                </Link>
              </div>
            </Block>
          </Block>
        </Row>
      </>
  )
}
