import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Block, Row } from 'jsxstyle'
import LoadingSpinner from "../components/spinner";
import Header from "../components/header";
import Athlete from "../components/athlete";
import Circle from "../components/circle";
import Embedded from "../components/embedded";
import { oauth } from '../oauth'
import { getCircleShortName, getTime } from '../util.js'

export default function Home() {
 const [period, setPeriod] = useState(1);
 const [user, setUser] = useState(null);        // The user is the 'athlete' that is logged in to Strava
 const [athlete, setAthlete] = useState(null);  // The athlete is the active 'athlete' (info, leaderbord, trophies)
 const [circleIndex, setCircleIndex] = useState(2);
 const [circle, setCircle] = useState(null);
 const [isLoading, setIsLoading] = useState(false);
 const [sorted, setSorted] = useState('Meters');
 const [embeddedBrowser, setEmbeddedBrowser] = useState(false)

 const navigate = useNavigate();

 const API_URL = process.env.REACT_APP_API_URL

 useEffect(() => {
  setIsLoading(true)
  const user = JSON.parse(localStorage.getItem('hm-user'))
  //console.log('Home - user', user)
  if (user && user.created) {
    setCircleIndex(0)
    setUser(user);
    setAthlete(user);
    setIsLoading(false);
  } else {
    setIsLoading(false)
    navigate("/")
  }
  try {
     const agent = navigator.userAgent
     const isEmbeddedBrowser = /FBAN|FBAV|FBMS|FB_IAB|FB4A|FBAN\/Messenger/.test(agent);
     setEmbeddedBrowser(isEmbeddedBrowser)
  } catch (err) {
     console.log('There was an error ' + err.message)
  }

}, [])

  const goHome = () => {
    setIsLoading(true);
    if (athlete && athlete._id) {
     setCircle(null)
     setAthlete(user)
     setCircleIndex(0)
    }
    setTimeout(() => {
     setIsLoading(false);
    }, 400);
  }

  function logout () {
   setIsLoading(true)
   localStorage.removeItem('hm-user')
   setTimeout(() => {
  	 setCircle(null)
  	 setAthlete(null)
     setUser(null)
     navigate("/");
   }, 500);
  }

  const getCircle = async (id, period, sorted) => {
    const url = API_URL + 'circles/' + id
    const date = new Date()
    const timezoneOffset = date.getTimezoneOffset()
    const params = {
        period: period,
        sort: sorted,
        athleteId: athlete._id,
        athleteName: athlete.strava.firstname + ' ' + athlete.strava.lastname,
        date: date.toString(),
        timezoneOffset: timezoneOffset
    }
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    })
    const newCircle = await response.json()
    setCircle(newCircle)
  }

  const changeAthlete = async (id) => {
    setIsLoading(true)
    setCircle(null);
    const response = await fetch(API_URL + 'athletes/' + id);
    const athlete = await response.json()
    setCircleIndex(0)
    setAthlete(athlete);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }

  const updatePeriod = async (period) => {
    setPeriod(period)
    await getCircle(circle._id, period, sorted)
  }

  return (
    <>
      {user && user.strava ? (
        <Header profile={user.strava.profile} logout={logout} goHome={goHome} />
      ) : (
        <Header logout={logout} goHome={goHome} />
      )}
      <Row justify-content="center">
        <Block style={{ fontWeight: 300, lineHeight: '19px', fontSize: '18px', maxWidth: '500px'}}>
          {isLoading ? (
            <Row justify-content="center">
              <LoadingSpinner />
            </Row>
          ) : (
            <Block>
              {embeddedBrowser ? (
                <Embedded />
              ) : (
                <Block>
                  <Athlete
                      athlete={athlete}
                      changeAthlete={changeAthlete}
                      circleIndex={circleIndex}
                      setCircleIndex={setCircleIndex}
                      circle={circle}
                      getCircle={getCircle}
                      sorted={sorted}
                      setSorted={setSorted}
                      period={period}
                      updatePeriod={updatePeriod}
                      />
                </Block>
              )}
            </Block>
          )}
        </Block>
      </Row>
    </>
  );
}

/* CIRCLE
 const [showACircle, setShowACircle] = useState(false)
setShowACircle(false)

 const showCircle = async (circleId) => {
  if (circleId) {
    getCircle(circleId, period, sorted)
  }
  console.log('Gobba sow circle', circleId)
  setShowACircle(true)
 }

 {showACircle && (
   <Circle
       circle={circle}
       getCircle={getCircle}
       changeAthlete={changeAthlete}
       sorted={sorted}
       setSorted={setSorted}
       period={period}
       updatePeriod={updatePeriod}
       />
 )}
 */
